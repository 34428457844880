<script>
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      salaryItems: [],
      levelThreeAccounts: [],
      levelThreeAdAccounts: [],
      levelTwoAdAccounts: [],
      levelTwoAccounts: [],
      branch: {},
      branchItem: {
        isNew: 1,
        depreciable: 1,
        code: null,
        account: null,
        Adaccount: null,
      },
    };
  },
  methods: {
    // selectedAccount(account) {
    //   let type = "Asset";
    //   this.http
    //     .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
    //       search: account,
    //       level: 3,
    //       type,
    //     })
    //     .then((res) => {
    //       if (res.status) {
    //         this.levelThreeAccounts = res.data.map((a) => {
    //           return this.$i18n.locale == "ar"
    //             ? a.code + "-" + a.name
    //             : a.code + "-" + a.name_en;
    //         });
    //       }
    //     });
    // },

    selectedAccount(account) {
      this.http
        .post("fixed-assets/types/setup/accounts/search", {
          search: account,
          type: "account",
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    selectedAdAccount(account) {
      this.http
        .post("fixed-assets/types/setup/accounts/search", {
          search: account,
          type: "ad account",
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAdAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    // selectedAdAccount(account) {
    //   let type = "Asset";
    //   this.http
    //     .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
    //       search: account,
    //       level: 3,
    //       type,
    //     })
    //     .then((res) => {
    //       if (res.status) {
    //         this.levelThreeAdAccounts = res.data.map((a) => {
    //           return this.$i18n.locale == "ar"
    //             ? a.code + "-" + a.name
    //             : a.code + "-" + a.name_en;
    //         });
    //       }
    //     });
    // },
    addItem() {
      console.log(this.branchItem);
      this.http
        .post("fixed-assets/types", {
          branchItem: this.branchItem,
        })
        .then((res) => {
          if (res.status) {
            this.branchItem = {
              isNew: 1,
              depreciable: 1,
              item: {},
              code: null,
              account: null,
              Adaccount: null,
            };
            this.$router.push("/assets-categories");
          }
        });
    },
    // selectedParentAccount(account) {
    //   this.http
    //     .post("fixed-assets/types/parents/search", { search: account })
    //     .then((res) => {
    //       if (res.status) {
    //         this.levelTwoAccounts = res.data.map((a) => {
    //           return this.$i18n.locale == "ar"
    //             ? a.code + "-" + a.name
    //             : a.code + "-" + a.name_en;
    //         });
    //       }
    //     });
    // },
    // selectedParentAdAccount(account) {
    //   this.http
    //     .post("fixed-assets/types/parents/search", { search: account })
    //     .then((res) => {
    //       if (res.status) {
    //         this.levelTwoAdAccounts = res.data.map((a) => {
    //           return this.$i18n.locale == "ar"
    //             ? a.code + "-" + a.name
    //             : a.code + "-" + a.name_en;
    //         });
    //       }
    //     });
    // },
    getBranch() {
      const currentUser = JSON.parse(localStorage.currentUser);
      this.http.get("branches/" + currentUser.branch_id).then((res) => {
        this.branch = res.data;
      });
    },
  },
  computed: {
    filteredAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
    // filteredParentAccounts() {
    //   return this.levelTwoAccounts.filter((account) => {
    //     return account;
    //   });
    // },
    filteredAdAccounts() {
      return this.levelThreeAdAccounts.filter((account) => {
        return account;
      });
    },
    // filteredParentAdAccounts() {
    //   return this.levelTwoAdAccounts.filter((account) => {
    //     return account;
    //   });
    // },
  },
  created() {
    this.getBranch();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/assets-categories`">
            <h4 class="m-0">
              {{ $t("fixed_assets.categories") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>
            {{
              $t("fixed_assets.add_cat") +
              ` (${$i18n.locale == "ar" ? branch?.name : branch?.name_en})`
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row p-2" style="background-color: rgb(239, 237, 237)">
              <form class="mb-3" @submit.prevent="addItem">
                <div class="mb-3">
                  <label for="">{{ $t("popups.name_ar") }}</label>
                  <input
                    v-model="branchItem.name"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="mb-3">
                  <label for="">{{ $t("popups.name_en") }}</label>
                  <input
                    v-model="branchItem.name_en"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="mb-3">
                  <label for="">{{ $t("salaryItems.qs") }}</label
                  ><br />
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="branchItem.isNew"
                      value="1"
                      name="flexRadioDefault"
                      checked
                      id="flexRadioDefault1"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      {{ $t("salaryItems.new") }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="0"
                      v-model="branchItem.isNew"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      {{ $t("salaryItems.exist") }}
                    </label>
                  </div>
                </div>
                <div class="mb-3" v-if="parseInt(branchItem.isNew)">
                  <label for="">{{ $t("fixed_assets.depreciable") }}</label
                  ><br />
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="branchItem.depreciable"
                      value="1"
                      name="flexRadioDefault3"
                      checked
                      id="flexRadioDefault3"
                    />
                    <label class="form-check-label" for="flexRadioDefault3">
                      {{ $t("popups.yes") }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="0"
                      v-model="branchItem.depreciable"
                      name="flexRadioDefault4"
                      id="flexRadioDefault4"
                    />
                    <label class="form-check-label" for="flexRadioDefault4">
                      {{ $t("popups.no") }}
                    </label>
                  </div>
                </div>
                <div class="mb-3" v-if="parseInt(branchItem.isNew)">
                  <label for="">Account NO.</label>
                  <input
                    v-model="branchItem.code"
                    required
                    type="number"
                    class="form-control"
                  />
                </div>
                <div class="mb-3" v-if="parseInt(branchItem.isNew)">
                  <label for="">Accumulated Depreciation Account NO.</label>
                  <input
                    v-model="branchItem.ad_code"
                    required
                    type="number"
                    class="form-control"
                  />
                </div>

                <div v-if="!parseInt(branchItem.isNew)" class="mb-3">
                  <label for="">{{ $t("fixed_assets.search_account") }}</label
                  ><br />
                  <AutoComplete
                    @keyup="selectedAccount(branchItem.account)"
                    v-model="branchItem.account"
                    :suggestions="filteredAccounts"
                    @complete="branchItem.account = $event.query"
                    style="
                      color: #333 !important;
                      border-radius: 10px !important;
                    "
                  />
                </div>
                <div v-if="!parseInt(branchItem.isNew)" class="mb-3">
                  <label for="">{{
                    $t("fixed_assets.search_ad_Account")
                  }}</label
                  ><br />
                  <AutoComplete
                    @keyup="selectedAdAccount(branchItem.Adaccount)"
                    v-model="branchItem.Adaccount"
                    :suggestions="filteredAdAccounts"
                    @complete="branchItem.Adaccount = $event.query"
                    style="
                      color: #333 !important;
                      border-radius: 10px !important;
                    "
                  />
                </div>

                <hr class="mt-2" />
                <div
                  class="float-end"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                >
                  <router-link to="/salary-items">
                    <button
                      style="margin-inline-end: 4px"
                      type="button"
                      class="btn btn-secondary"
                    >
                      {{ $t("popups.cancel") }}
                    </button>
                  </router-link>
                  <button type="submit" class="btn btn-primary">
                    {{ $t("popups.add") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped></style>
